<template>
  <div>
    <div class="nav">
      <div style="display: flex;align-items: center;margin-left: 200px;height: 100%;">
        <img style="height: 37px;width: 50px;border-radius:5px;vertical-align: middle;" src="../../assets/log_hikoon.png" alt="" srcset="">
       <span style="color: #FFFFFF;vertical-align: middle;font-size: 18px;margin-left: 15px;">Hikoon Music</span>
      </div>
    </div>
    <div class="content">
      <div class="card card-active" v-if="step === 1" style="height: 414px;width: 580px;padding-bottom: 0">
        <div class="tag-title">安全验证</div>

        <div class="title">
          Hikoon的 <span style="color: #E6121E">{{ name }}</span> 邀请您上传歌曲文件
        </div>

        <el-form ref="form" :model="form" label-width="80px" label-position="top" style="margin-top: 40px">
          <el-form-item label="验证码">
            <el-input v-model="form.code" placeholder="请输入验证码"></el-input>
          </el-form-item>
        </el-form>

        <div style="color: #E6121E;font-size: 14px;font-weight: 300;" v-show="!!errorMsg">{{ errorMsg }}</div>

        <div style="display: flex;align-items: center;justify-content: right;color: #FF004D;font-size: 20px;margin-top: 42px;position: absolute;right: 47px;bottom: 49px" @click="next">
          <div style="cursor: pointer">下一步</div>
          <img src="../../assets/share-next.png" alt="previous" style="margin-left: 12px;width: 36px;height: 36px;cursor: pointer" >
        </div>
      </div>

      <div class="card" v-else>
        <div class="tag-title">上传文件</div>
        <div class="info">
<!--          <div class="label">项目名称<span class="value">{{songInfo.project ? songInfo.project.name : '/' }}</span></div>-->
          <div class="label">歌曲名称<span class="value">{{ songInfo.song_name }}</span></div>
          <div class="label">艺人名称<span class="value">{{ songInfo.singer }}</span></div>
          <div class="label">歌曲版本<span class="value">{{ songInfo.version_note ? songInfo.version_note : '/' }}</span></div>
        </div>

        <el-divider></el-divider>

        <div style="color: #333333;font-weight: 700">上传文件</div>

        <div class="notice">
          <img src="../../assets/share-notice.png" alt="notice" style="width: 20px;height: 20px;margin-left: 20px;margin-right: 4px">
          <div>上传完成前，请不要关闭此页面</div>
        </div>

        <div class="upload-button" style="margin-bottom: 72px" @click="handleAddFile">
          <img src="../../assets/share-plus.png" alt="add" style="width: 20px;height: 20px;margin-right: 8px"/>
          添加
        </div>

        <div style="color: #333333;" v-show="fileList.length">正在上传 (1/{{ fileList.length }})</div>
        <upload-file v-for="(_, index) in fileList " v-model="fileList[index]" :key="_.key" :index="index" @handle-delete="handleDelete" :token="token" :id="songInfo.id" :fileType="fileType"></upload-file>
        <upload-file v-for="(_, index) in songList" v-model="songList[index]" :key="_.id" :index="index" @handle-delete="handleDelete" :token="token" :id="songInfo.id" :fileType="fileType" read-only></upload-file>
      </div>
    </div>
    <div style="text-align: center;color: #787878;font-size: 12px;margin-top: 18px">
      北京海葵科技有限公司版权所有
    </div>
  </div>
</template>

<script>
import uploadFile from "./components/upload-file.vue"
import {
  // fileSave,
  getProjectDetailsApi,
  getProjectDetailsListApi,
  shareCheck,
  shareFile,
  shareFileType,
  shareShow,
  shareUser
} from "@/api/issue"
// import moment from "moment/moment";

export default {
  name: "Share",

  props: {
    code: {
      type: String
    }
  },

  components: {
    uploadFile
  },
  computed:{
     isLogin(){
        return this.$route.name === 'shareMusician'
     }
  },
  data(){
    return {
      step: 1,
      name: '',
      token: '',
      fileList: [],
      // 活动性质 请根据实际上传文件勾选对应的文件类型，以下所有分轨要齐头
      fileType: [],
      form: {
        code: ''
      },
      errorMsg: '',

      songInfo: {
        id: undefined,
        project: {name: '', created_at: ''},
        song_name: '',
        singer: '',
        version_note: ''
      },
      songList: [],
      index:0
    }
  },

  mounted() {
    if(this.isLogin){
      this.step = 2;
      this.getProjectDetails()
      this.getProjectDetailsList()
      this.shareFileType()
    }else {
      shareUser(`${process.env.VUE_APP_BASE_API_HTML}/#/share/` + this.code).then(r => {
        if (r.code === 0){
          this.name = r.data.name
        }
      })
    // 验证token 是否过期
      const shareToken = JSON.parse(localStorage.getItem('share_token')) || {}
       if(shareToken[this.code]?.expireTime > new Date().getTime()){
         this.token = `${shareToken[this.code].token}`
         this.step = 2
         this.getShareShow()
         this.shareFile()
         this.shareFileType()
       }
    }
  },

  methods: {
    next(){
      if (!this.form.code){
        return this.errorMsg = '请输入验证码！'
      }
      shareCheck({
        url: `${process.env.VUE_APP_BASE_API_HTML}/#/share/` + this.code,
        phone: this.form.code,
        from: 1
      }).then(r => {
        if (r.code === 0 && r.data) {
          const shareToken = JSON.parse(localStorage.getItem('share_token')) || {}
          const token = r.data.token
          this.token = token
          shareToken[this.code] = {token, expireTime: (new Date()).getTime() + 24 * 3600 * 1000}
          // todo 清除过期token
          localStorage.setItem('share_token', JSON.stringify(shareToken))
          this.step = 2
          this.getShareShow()
          this.shareFile()
          this.shareFileType()
        } else {
           if(r.msg === '链接验证失败') this.errorMsg = '您的验证码输入有误，请重新输入！'
            else this.errorMsg = '链接已失效，请联系邀请人重新提供！'
        }
      })

    },
    handleAddFile(){
      this.fileList.unshift({name: '', key:this.index++})
    },
    handleDelete(index){
      this.fileList.splice(index, 1)
    },
    getShareShow(){
      shareShow(this.token).then(r => {
        if(r.code === 0 && r.data){
          this.songInfo = r.data
        }
      })
    },
    shareFile(){
      return shareFile(this.token).then(r => {
        this.songList = r.data.list
      })
    },
    shareFileType(){
      shareFileType().then(r => {
        this.fileType = r.data
      })
    },
    //已登录上传详情
     getProjectDetails(){
       getProjectDetailsApi({track_id:this.code}).then(r => {
         if(r.code === 0 && r.data){
           this.songInfo = r.data
         }
       })
     },
    // 已经登录列表
    getProjectDetailsList(){
      getProjectDetailsListApi({track_id:this.code,pageSize:1000}).then(r =>{
        this.songList = r.data.list
      })
    },
  }
}
</script>

<style scoped lang="scss">
*{
  font-weight: normal;
}
::v-deep .el-divider{
  background:#f5f5f5;
}
.nav{
  background-color: #000000;
  min-height: 53px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  font-weight: normal;
}
.content{
  font-weight: normal;
  background-color: #FFFFFF;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  min-height: calc(100vh - 110px);
  >.card{
    margin-top: 50px;
    margin-bottom: 50px;
    width: 900px;
    box-shadow: 4px 4px 20px 0 rgba(0,0,0,0.25);
    padding: 0 60px 76px 60px;
    position: relative;
     &.card-active{
      margin-top: 150px;
    }
    >.title{
      font-size: 20px;
      margin-top: 64px;
    }
    >.tag-title{
      background-color: #000000;
      color: #FFFFFF;
      width: 160px;
      line-height: 44px;
      border-radius: 0 0 10px 10px;
      box-shadow: 2px 2px 15px 0 rgba(0,0,0,0.25);
      text-align: center;
    }
    :deep(.el-form-item__label){
      color: #4f4f4f;
      font-size: 16px;
    }
    >.info{
      display: flex;
      font-size: 14px;
      line-height: 40px;
      flex-wrap: wrap;
      padding-top: 30px;
      >.label{
        width: 360px;
        color: #828282;
        >.value{
          padding-left: 20px;
          color: #000000;
        }
      }
    }
    >.notice{
      display: flex;
      align-items: center;
      height: 50px;
      border-radius: 4px;
      background: rgba(255,238,205,0.50);
      font-size: 14px;
      line-height: 50px;
      color: #775834;
      margin-top: 20px;
    }
    >.upload-button{
      background-color: transparent;
      height: 50px;
      border: 1px dashed rgba(255,0,77,0.50);
      border-radius: 4px;
      margin-top: 20px;
      cursor: pointer;
      &:hover{
        background-color: #fff7f8;
      }
    }
  }
}
</style>
