<template>
  <div>
    <div style="margin-top: 34px;display: flex" v-show="!isShowDialog">
      <img src="../../../assets/share-file.png" alt="" style="width: 48px;height: 48px;margin-right: 16px" @click="isShowDialog = true">

      <div style="flex: 1">
        <div class="file-name-time">
          <div class="file-name">{{ file.name }}</div>
          <div class="file-time" v-show="percentage === 100 || readOnly">
            {{ value.created_at }}
            <img src="../../../assets/share-ok.png" alt="ok" style="width: 16px;height: 16px;margin-left: 10px">
          </div>
        </div>

        <el-progress :percentage="percentage" define-back-color="#FFE6EE" :show-text="false" :stroke-width="4" style="padding-top: 8px" v-show="percentage !== 100 && !readOnly"></el-progress>

        <div class="file-detail">
          <div class="file-size">{{ byteSize(file.size) }}</div>
          <button class="file-detail-button" @click="isShowDetail = !isShowDetail">
            <div>详情</div>
            <img src="../../../assets/share-detail.png" alt="detail" class="arrow" :class="{active: isShowDetail}">
          </button>
        </div>

        <transition name="detail">
          <div class="file-detail-content" v-show="isShowDetail">
            <template v-if="this.readOnly">
              <div class="file-detail-content-item" v-for="item in value.ptf_types" :key="item.type.id">
                {{ getFileTypeById(item.type.id) }}
              </div>
            </template>
            <template v-else>
              <div class="file-detail-content-item" v-for="item in form.type" :key="item">
                {{ getFileTypeById(item) }}
              </div>
            </template>
          </div>
        </transition>
      </div>

      <div style="margin-left: 4px;margin-top: 14px" v-show="percentage !== 100 && !readOnly">
        <img src="../../../assets/share-start.png" alt="start" class="file-button" v-show="!uploading" @click="handleRestart">
        <img src="../../../assets/share-pause.png" alt="pause" class="file-button" v-show="uploading" @click="handlePause">
        <img src="../../../assets/share-cancel.png" alt="cancel" class="file-button" @click="show = true;handlePause()">
      </div>
    </div>

    <el-dialog
        class="el-dialog-upload"
        title="上传歌曲文件"
        :visible.sync="isShowDialog"
        center
        :before-close="handleDelete"
        custom-class="elDialog"
        width="700px">
      <el-form ref="form" :model="form" label-width="90px">
        <el-form-item label="选择文件">
          <el-upload
              v-model="form.file"
              drag
              accept=".zip,.rar"
              :before-upload="beforeUpload"
              action="/">
            <img src="../../../assets/upload.png" alt="upload" style="width: 17px;height: 16px;margin-top: 13px">
            <div class="el-upload__tip" slot="tip" v-show="!!file.name">{{ file.name }}(文件大小：{{ byteSize(file.size) }})</div>
            <div class="el-upload__text"><em>点击或拖拽上传</em></div>
          </el-upload>
          <div style="position: absolute;top: 2px;right:-25px;width: 230px;font-size: 12px;color: #999999;line-height: 22px">
            只支持单个文件上传，<br>
            多个文件请打包上传， 最大不超过10G <br>
            文件格式只限.zip和.rar
          </div>
        </el-form-item>
        <el-form-item label="文件类型">
          <div style="color: #828282">请根据实际上传文件勾选对应的文件类型，以下所有分轨要齐头</div>
          <el-checkbox-group v-model="form.type" style="display: block">
            <div v-for="item in fileType" :key="item.id">
              <el-checkbox :label="item.id" name="type">
                {{item.type_name + (item.remark ? '(' + item.remark + ')' : '') }}
              </el-checkbox>
            </div>
          </el-checkbox-group>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="handleDelete">取 消</el-button>
        <el-button style="color: #FFFFFF;background: #ff004d;" type="primary" @click="handleSubmit" :disabled="!file.name">确认提交</el-button>
      </span>
    </el-dialog>
    <hi-custom-popup :show.sync="show"  @cancel="handleRestart" @confirm="handleCancel" :but-show="true" >
      <div style="padding: 55px 0;text-align: center;">
        确认删除上传任务： {{file.name}} ？
      </div>
    </hi-custom-popup>
  </div>

</template>

<script>
import Cos from "@/utils/cos"
import byteSize from "byte-size"
import moment from "moment"
import {fileSave} from "@/api/issue"
import hiCustomPopup from "@/components/HiCustomPopup.vue";

export default {
  name: "upload-file",
components:{
  hiCustomPopup
},
  props: {
    value: {
      type: Object,
      default() {
        return {
          name: '',
          size_byte: 0,
          created_at: ''
        }
      }
    },
    index: Number,
    token: String,
    id: {
      type: Number,
      default: 0
    },
    readOnly: {
      type: Boolean,
      default: false
    },
    fileType: {
      type: Array,
      default() {
        return []
      }
    }
  },

  data() {
    return {
      // 显示详情
      isShow: false,
      // 展开
      isShowDetail: false,
      // 显示弹窗
      isShowDialog: false,
      form: {
        url: '',
        type: []
      },
      file: {
        name: '',
        size: 0,
        type: ''
      },
      cos: undefined,
      // 当前上传进度 0~1
      percentage: 0,
      // 正在上传
      uploading: true,
      taskId: undefined,
      show:false,
    }
  },

  mounted() {
    if (this.readOnly){
      this.file.name = this.value.name
      this.file.size = this.value.size_byte
    } else {
      this.isShowDialog = true
    }
  },

  methods: {
    byteSize(size) {
      return byteSize(size)
    },
    beforeUpload(file){
      this.file = file
      // property/tracks/年月/项目ID-歌曲文件ID
      this.cos = new Cos({
        isLogin:this.$parent.isLogin,
        file,
        dir: this.dir,
        token: this.token
        // onProgress: this.onProgress,
        // onTaskReady: this.onTaskReady
      })
      return false
    },
    handleSubmit(){
      if(this.form.type.length === 0) return this.$popup({msg:'至少选择一种文件类型。'})
      this.isShow = true
      this.cos.upload({
        onProgress: this.onProgress,
        onTaskReady: this.onTaskReady
      }).then(data => {
        this.form.url = data.Location
        console.log('上传成功',data)
        this.fileSave(data)
      })
      this.isShowDialog = false
    },
    onProgress(progressData) {
      if (progressData.percent) {
        this.percentage = progressData.percent * 100
      }
    },
    onTaskReady(taskId) {
      this.taskId = taskId
    },
    handleDelete(){
      this.$emit('handle-delete', this.index)
    },
    handlePause(){
      this.cos.pause(this.taskId)
      this.uploading = false
    },
    handleRestart(){
      this.cos.restart(this.taskId)
      this.uploading = true
    },
    handleCancel(){
      this.cos.cancelTask(this.taskId)
      this.handleDelete()
    },
    fileSave(data){
      let p = {
        file_types: this.form.type,
        name: this.file.name,
        size: this.file.size,
        upload_ip: '127.0.0.1',
        from:this.$parent.isLogin ? 1 : 2, // 来源: 1:分贝登录用户 2: 分享链接用户
        dir: this.dir,
        location: 'https://' + data.Location,
        url: data.Key,
        mime: this.file.type,
      }
      if(this.$parent.isLogin) p.track_id = this.id
      return fileSave(p, this.token).then( () => {
        this.$emit('input', {...this.value, created_at: moment().format('YYYY-MM-DD HH:mm:ss'),ptf_types:[1,2]})
      })
    },
    getFileTypeById(id){
      const fileType = this.fileType.find(e => e.id === id)
      if (fileType){
        return fileType.type_name + (fileType.remark ? '(' + fileType.remark + ')' : '')
      } else {
        return ''
      }
    }
  },

  computed: {
    dir() {
      return `property/tracks/${moment().format('YYYYMM')}/${this.id}`
    }
  }
}
</script>

<style scoped lang="scss">
.file-name-time{
  display: flex;
  justify-content: space-between;
  .file-name{
    font-size: 14px;
    color: #333333;
  }
  .file-time{
    display: flex;
    align-items: center;
    color: #BDBDBD;
    font-size: 12px;
  }
}
.file-detail{
  margin-top: 8px;
  display: flex;
  font-size: 12px;
  .file-size{
    color: #BDBDBD;
    margin-right: 21px;
    width: 60px;
  }
  .file-detail-button{
    color: #FF004D;
    cursor: pointer;
    display: flex;
    align-items: center;
    border: 0;
    padding: 0;
    background-color: transparent;
    .arrow{
      width: 10px;
      height: 6px;
      margin-left: 4px;
      transition-duration: 0.2s;
    }
    .active{
      transform: rotate(180deg);
      transition-duration: 0.2s;
    }
  }
}
.file-button{
  width: 32px;
  height: 32px;
  margin-left: 4px;
  cursor: pointer;
}
.file-detail-content{
  margin-top: 8px;
  transition-duration: 0.2s;
  .file-detail-content-item{
    color: #828282;
    font-size: 12px;
    line-height: 22px;
  }
}
.detail-enter-active, .detail-leave-active {
  transition-duration: 0.2s;
}
.detail-enter, .detail-leave-to {
  transition-duration: 0.2s;
  opacity: 0;
}

:deep(.elDialog){
  border-radius: 10px;
}
:deep(.el-upload-dragger){
  width: 312px;
  height: 72px;
  border: 1px dashed rgba(255,0,77,0.50);
  line-height: 20px;
  em{
    font-size: 12px;
  }
}
:deep(.el-form-item__label){
  font-size: 14px;
  color: #333333;
  padding-right: 24px;
}
:deep(.el-upload__tip){
  line-height: 14px;
  margin-top: 0;
  font-size: 12px;
}
::v-deep .el-dialog-upload{
   .el-dialog__header{
    padding-top: 46px;
    .el-dialog__title{
      font-size: 18px;
      font-family: PingFang SC, PingFang SC-Semibold;
      font-weight: 600;
      color: #222222;
    }
  }
  .el-dialog__body{
    padding-left: 54px;
  }
  .el-dialog__footer{
    padding-bottom: 43px;
  }
  .dialog-footer{
    padding-bottom: 23px;
    .el-button{
      width: 188px;
      height: 42px;
      background: #f7f7f7;
      border-radius: 3px;
      font-size: 15px;
      font-family: PingFang SC, PingFang SC-Regular;
      font-weight: 400;
      border: none;
      margin: 0 12px;
    }
  }
}
</style>
