import COS from "cos-js-sdk-v5"
import {getCosToken, getCosTokenApi} from "@/api/issue"

class Cos {
  cos
  dir
  type
  bucket
  isLogin

  /**
   *
   * @param isLogin {boolean} 是否登录
   * @param file {File} 文件对象
   * @param dir {string} 文件路径
   * @param type {string}
   * @param bucket {string}
   * @returns {*}
   */
  constructor({isLogin = false,file, dir, token,}) {
    this.file = file
    this.dir = dir
    this.type = ['development','test'].includes(process.env.NODE_ENV) ? 'property' : 'material'
    this.bucket = ['development','test'].includes(process.env.NODE_ENV) ? 'legal-test-1305250541' : 'legal-1305250541'; //区分环境
    this.isLogin = isLogin
    let that = this
    this.cos = new COS({
      async getAuthorization(options, callback) {
        await (isLogin ?  getCosTokenApi({dir,type:that.type}) :getCosToken({
          dir,
          type:that.type
        }, token)).then(r => {
          let data = r.data
          let credentials = r.data.credentials

          if (!data || !credentials) return console.error('credentials invalid');
          callback({
            TmpSecretId: credentials.tmpSecretId,
            TmpSecretKey: credentials.tmpSecretKey,
            XCosSecurityToken: credentials.sessionToken,
            StartTime: data.startTime, // 时间戳，单位秒，如：1580000000，建议返回服务器时间作为签名的开始时间，避免用户浏览器本地时间偏差过大导致签名错误
            ExpiredTime: data.expiredTime, // 时间戳，单位秒，如：1580000900
            ScopeLimit: true,
          });
        })
      }
    })

    return this
  }

  upload({onProgress, onTaskReady}){
    let Region = "ap-shanghai"
    let file_key = this.dir + '/' + this.file.name
    return new Promise((resolve, reject) => {
      this.cos.sliceUploadFile({
        Bucket: this.bucket,
        Region: Region,
        Key: file_key,
        StorageClass: 'ARCHIVE',    //'归档存储(ARCHIVE) 属冷数据类型 ，'STANDARD_IA'  ,无损音频使用'STANDARD_IA''
        // ACL:'public-read',
        Body: this.file,
        onTaskReady,
        onHashProgress: function (progressData) {       /* 非必须 */
          console.log(JSON.stringify(progressData));
        },
        onProgress
      }, (err, data) => {
        if (data) {
          resolve(data)
        } else {
          reject(err)
        }
      })
    })
  }

  pause(taskId){
    return this.cos.pauseTask(taskId)
  }
  restart(taskId){
    return this.cos.restartTask(taskId)
  }
  cancelTask(taskId){
    this.cos.cancelTask(taskId)
  }
}
export default Cos
