import request from '@/utils/request'

/**
 * @description 专辑列表
 */
export const getAlbumList = (params,url='',url2="") => request({
    url: `/api/issue/universal/${url}/${url2}`,
    method: 'post',
    data:params
})

/**
 * @description 歌曲列表
 */
 export const getSongList = (params,url='',url2="") => request({
    url: `/api/issue/universal/${url}/${url2}`,
    method: 'post',
    data:params
})

/**
 * @description 机构成员动态
 */
 export const getUserDynamics = (params) => request({
    url: `/api/company/user/dynamics`,
    method: 'post',
    data:params
})

/**
 * @description 成员动态操作人下拉列表
 */
 export const getUserList = (params) => request({
    url: `/api/company/user/nameList`,
    method: 'post',
    data:params
})

/**
 * @description 专辑草稿总数
 */
 export const getDraftCount = (params,url='',url2="") => request({
    url: `/api/issue/universal/${url}/${url2}`,
    method: 'post',
    data:params
})

/**
 * @description 撤销专辑
 */
 export const albumCancel = (params,url='',url2="") => request({
    url: `/api/issue/universal/${url}/${url2}`,
    method: 'post',
    data:params
})

/**
 * @description 修改申请列表
 */
 export const getUpdateList = (params,url='',url2="") => request({
    url: `/api/issue/universal/${url}/${url2}`,
    method: 'post',
    data:params
})

export const deleteAlbum = (albumId) => request({
  url: `/api/issue/album/delete`,
  method: 'get',
  params: {
    albumId
  }
})
/**
 * 分享上传链接
 * GET /track/share
 * 接口ID：52878772
 * 接口地址：https://www.apifox.cn/link/project/1254960/apis/api-52878772
 * @returns {AxiosPromise}
 */
export const getTrackShare = (id) => request({
  url: `/api/property/universal/track/share?track_id=` + id,
  method: 'get'
})

/**
 * 生成分享链接
 * POST /track/shareCreate
 * 接口ID：52883264
 * 接口地址：https://www.apifox.cn/link/project/1254960/apis/api-52883264
 * @param data
 * @returns {AxiosPromise}
 */
export const shareCreate = (data) => request({
  url: `/api/property/universal/track/shareCreate`,
  method: 'post',
  data
})

/**
 * 分享链接用户信息
 * GET /track/shareUser
 * 接口ID：53067639
 * 接口地址：https://www.apifox.cn/link/project/1254960/apis/api-53067639
 * @param url
 * @returns {*}
 */
export const shareUser = (url) => request({
  url: '/api/property/universalV2/track/shareUser',
  method: 'get',
  params: {
    share_url: url
  }
})

/**
 * 分享链接验证
 * POST /track/shareCheck
 * 接口ID：53103100
 * 接口地址：https://www.apifox.cn/link/project/1254960/apis/api-53103100
 * @param data
 * @returns {*}
 */
export const shareCheck = (data) => request({
  url: '/api/property/universalV2/track/shareCheck',
  method: 'post',
  data
})

/**
 * 歌曲详情(分享页)
 * GET /track/shareShow
 * 接口ID：53089412
 * 接口地址：https://www.apifox.cn/link/project/1254960/apis/api-53089412
 * @param id
 * @returns {AxiosPromise}
 */
export const shareShow = (token) => request({
  url: '/api/property/universalV2/track/shareShow',
  method: 'get',
  headers: {
    token: token
  }
})
/**
 * 获取文件上传cos-token
 * POST /track/getCosToken
 * 接口ID：53104842
 * 接口地址：https://www.apifox.cn/link/project/1254960/apis/api-53104842
 * @param data
 * @param token
 * @returns {AxiosPromise}
 */
export const getCosToken = (data, token) => request({
  url: '/api/property/universalV2/track/getCosToken',
  method: 'post',
  headers: {
    token: token
  },
  data
})

/**
 * 歌曲文件上传保存
 * POST /track/fileSave
 * 接口ID：53109761
 * 接口地址：https://www.apifox.cn/link/project/1254960/apis/api-53109761
 * @param data
 * @param token
 * @returns {AxiosPromise}
 */
export const fileSave = (data, token) => request({
  url: '/api/property/track/fileSave',
  method: 'post',
  headers: {
    token: token
  },
  data
})

// export const trackFileSave = (data) => request({
//     url: '/api/property/track/fileSave',
//     method: 'post',
//     data
// })
/**
 * 歌曲文件列表(分享页)
 * GET /track/shareFile
 * 接口ID：53088405
 * 接口地址：https://www.apifox.cn/link/project/1254960/apis/api-53088405
 * @param token
 * @returns {AxiosPromise}
 */
export const shareFile = (token) => request({
  url: '/api/property/universalV2/track/shareFile',
  method: 'get',
  headers: {
    token: token
  },
  params: {
    pageSize: 100000
  }
})

/**
 * 歌曲文件类型
 * GET /track/shareFileType
 * 接口ID：53088043
 * 接口地址：https://www.apifox.cn/link/project/1254960/apis/api-53088043
 * @returns {AxiosPromise}
 */
export const shareFileType = () => request({
  url: '/api/property/universalV2/track/shareFileType',
  method: 'get'
})
/**
 * 歌曲详情
 * GET /track/show
 * 接口ID：51973719
 * 接口地址：https://www.apifox.cn/web/project/1254960/apis/api-51973719
 * @param params
 * @return {AxiosPromise}
 */
export const getProjectDetailsApi = (params) => request({
    url: '/api/property/universal/track/show',
    method: 'get',
    params:params
})

/**
 * 详情-文件列表
 * GET /track/file
 * 接口ID：52088692
 * 接口地址：https://www.apifox.cn/web/project/1254960/apis/api-52088692
 * @param params
 * @return {AxiosPromise}
 */
export const getProjectDetailsListApi = (params) => request({
    url: '/api/property/universal/track/file',
    method: 'get',
    params:params
})
export const getCosTokenApi = (data) => request({
    url: '/api/issue/universal/upload/getCosToken',
    method: 'post',
    data,
})
